import './App.css';

import React, {useEffect, useState} from 'react';

function App() {

    const [pairs, setPairs] = useState([]);

    //http://104.156.233.119:8663
    const url = "https://gaswap.top";
    useEffect(() => {
        fetch(url + '/api/v1/pairs')
            .then(response => response.json())
            .then(data => setPairs(data.data.list))
            .catch(error => console.error('Error fetching users:', error));
    }, []);

    return (
        <div className="container">
            <div>
                <p className="black-text">
                    Gašwap
                </p>
            </div>
            <div>
                <table className="bordered-table">
                    <thead>
                    <tr>
                        <th>Pool</th>
                        <th>Liquidity</th>
                        <th>Price</th>
                        <th>Payment Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pairs.map(pair => (
                        <tr key={pair.name}>
                            <td>{pair.base_chain_name} - {pair.quote_chain_name} </td>
                            <td>
                                <div>
                                    {Number(pair.base_token_amount).toFixed(4)} {pair.base_token_symbol}
                                </div>
                                <div>
                                    {Number(pair.quote_token_amount).toFixed(4)} {pair.quote_token_symbol}
                                </div>
                            </td>
                            <td> 1 {pair.base_token_symbol} = {Number(pair.quote_token_amount / pair.base_token_amount).toFixed(4)} {pair.quote_token_symbol}</td>
                            <td>
                                {pair.payment_contract}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div/>
            </div>
            <br></br>
            <br></br>
            <div className="qa-text">
                <p className="black-text">How to buy tokens? </p>
                <p>
                    Suppose you want to buy ETH on sepolia, you just need to send BNB to the payment address, wait a few minutes, and your wallet will receive ETH on sepolia.
                </p>
                <p className="black-text">How to sell tokens? </p>
                <p>
                    Suppose you want to sell ETH on sepolia, you just need to send SepETH to the payment address, wait a few minutes, and your wallet will receive BNB on BSC mainnet.
                </p>

                <p className="black-text">
                    How Gašwap works?
                </p>
                <p>
                    Each pool of Gašwap contains two tokens, which are equivalent in value. When a new token enters the pool, the Bancor protocol is used to calculate the number of another token and release it from the pool. As long as there is a transaction, the price of the pool will change.
                </p>
                <p className="black-text">
                    How much is the  fee?
                </p>
                <p>
                    A 2% fee will be charged for each transaction to increase the liquidity of the trading pair.
                </p>
                <p className="black-text">
                    Why didn't I receive the tokens?
                </p>
                <p className="red-text">
                    Each token has a minimum limit, such as 0.0001 BNB, 1 selETH, 10 holETH. Please do not send transactions with very small amounts, as such transactions will be ignored.
                </p>
            </div>
            <br></br>
            <div className="tip-text">
                <p>Holesky faucet</p>
                <ul>
                    <li> <a href="https://holesky-faucet.pk910.de/">holesky-faucet.pk910.de</a></li>
                    <li> <a href="https://faucet.quicknode.com/ethereum/holesky/">QuickNode Faucet</a></li>
                    <li> <a href="https://faucet.chainstack.com/holesky-testnet-faucet">Chainstack Faucet</a></li>
                </ul>
                <p>Sepolia faucet</p>
                <ul>
                    <li> <a href="https://sepolia-faucet.pk910.de/">sepolia-faucet.pk910.de</a></li>
                    <li> <a href="https://access.rockx.com/faucet-sepolia">Access.rockx Faucet</a></li>
                    <li> <a href="https://infura.io/faucet">Infura Faucet</a></li>
                </ul>
            </div>
        </div>
    );
}

export default App;
